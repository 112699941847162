<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                    :headers="headers"
                    :items="clinics"
                    :items-per-page="10"
                    :search="searchClinic"
                    :loading="loadingData"
                    :expanded="expanded"
                    single-expand
                    show-expand
                    item-key="id"
                    sort-by="id"
                    disable-sort
                    no-data-text="No hay datos para mostrar..."
                    loading-text="Cargando los datos, por favor espere!"
                    class="elevation-1 row-pointer"
                    :footer-props="{
                    showFirstLastPage: true,
                    disableItemsPerPage: false,
                    itemsPerPageAllText: 'Todas',
                    itemsPerPageText: 'Filas por página:'}"
            >   <!--@dblclick:row="(item, slot) => slot.expand(!slot.isExpanded)"-->
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''">
                            Clínicas
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                    id="findtext-clinic"
                                    label="Buscar.."
                                    name="findclinic"
                                    outlined
                                    dense
                                    single-line
                                    clearable
                                    background-color="white"
                                    hide-details
                                    v-model="searchClinic"
                                    class="white--text mt-0 pt-0 mr-2"
                                    prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialog" max-width="1000px" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  :small="$vuetify.breakpoint.smAndDown"
                                        color="blue darken-1"
                                        dark
                                        depressed
                                        elevation="0"
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 grey lighten-2">
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row class="mt-2">
                                                <v-col cols="12" md="7">
                                                    <v-text-field
                                                            v-model="editedItem.name"
                                                            label="Nombre"
                                                            :rules="textRules"
                                                            required>
                                                    </v-text-field>
                                                    <v-combobox
                                                            v-model="editedItem.address"
                                                            :items="places"
                                                            :loading="loadingPlaces"
                                                            :search-input.sync="searchQuery"
                                                            :rules="[v => !!v || 'Dirección de ubicación obligatoria']"
                                                            no-data-text="No hay resultados de búsqueda!"
                                                            item-text="place_name"
                                                            no-filter
                                                            clearable
                                                            required
                                                            label="Dirección de residencia"
                                                            v-on="$listeners"
                                                            v-on:change="getSelectedItem"
                                                            @click:clear="onClear">
                                                    </v-combobox>
                                                    <!--<v-autocomplete
                                                            v-model="editedItem.address"
                                                            :items="places"
                                                            :loading="isLoadingPlaces"
                                                            :search-input.sync="searchPlace"
                                                            :rules="[v => !!v || 'Dirección de ubicación obligatoria']"
                                                            no-data-text="No hay resultados de búsqueda!"
                                                            item-text="place_name"
                                                            item-value="place_name"
                                                            no-filter
                                                            clearable
                                                            required
                                                            label="Dirección de residencia"
                                                            v-on="$listeners"
                                                            @click:clear="onClear">
                                                    </v-autocomplete>-->
                                                    <v-text-field
                                                            v-model="editedItem.email"
                                                            :rules="emailRules"
                                                            label="E-mail"
                                                            required>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="5">
                                                    <v-textarea
                                                            v-model="editedItem.description"
                                                            label="Descripción"
                                                            :rules="textRules"
                                                            no-resize
                                                            rows="6"
                                                            counter
                                                            required>
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-simplemask
                                                        v-model="editedItem.phone1"
                                                        label="Teléfono 1"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            prefix: '+52',
                                                            suffix: '',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                            required: true,
                                                            rules: textRules
                                                        }"
                                                        v-bind:options="{
                                                            inputMask: '(###) ###-####',
                                                            outputMask: '##########',
                                                            empty: null,
                                                            applyAfter: false,
                                                            alphanumeric: false,
                                                            lowerCase: false,
                                                        }"
                                                        v-bind:focus="focus"
                                                        v-on:focus="focus = false"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field-simplemask
                                                        v-model="editedItem.phone2"
                                                        label="Teléfono 2"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            prefix: '',
                                                            suffix: '',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: ''
                                                        }"
                                                            v-bind:options="{
                                                            inputMask: '(###) ###-####',
                                                            outputMask: '##########',
                                                            empty: null,
                                                            applyAfter: false,
                                                            alphanumeric: false,
                                                            lowerCase: false,
                                                        }"
                                                            v-bind:focus="focus"
                                                            v-on:focus="focus = false"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model="editedItem.facebook_profile"
                                                            label="Perfil en Facebook"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model="editedItem.instagram_profile"
                                                            label="Perfil en Instagram"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model="editedItem.youtube_video"
                                                            label="Canal de Youtube"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model.number="editedItem.web_page"
                                                            label="Página Web"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model="editedItem.sanitary_license"
                                                            label="Licencia Sanitaria"
                                                            :rules="textRules"
                                                            required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                            dense
                                                            v-model="editedItem.servicehours"
                                                            label="Horarios de Atención"
                                                            :rules="textRules"
                                                            required
                                                    ></v-text-field>
                                                </v-col>
                                                <!--<v-col col="12">
                                                    <v-select
                                                            dense
                                                            v-model="editedItem.specialties"
                                                            :items="specialtiesList"
                                                            :rules="[v => !!v || 'Al menos una Especialidad es obligatoria']"
                                                            item-text="name"
                                                            item-value="id"
                                                            return-object
                                                            label="Especialidades"
                                                            multiple
                                                            item-color="primary"
                                                            small-chips
                                                            hint="Selecciones las especialidades del médico"
                                                            persistent-hint>
                                                    </v-select>
                                                </v-col>-->
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="primary" text @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline"
                                >Estás seguro que desea eliminar este elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeletePicture" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    Desea eliminar esta Imágen de la Galería?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeletePicture">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmPicture">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogError"
                            persistent
                            max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error de Validación</span>
                                </v-card-title>
            
                                <v-card-text>
                                    <div class="text-h6 pa-12">{{ messageError }}!</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn text @click.stop="closeDialogError">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                                :fullscreen="$vuetify.breakpoint.smAndDown"
                                v-model="dialogGalleryPhotos"
                                persistent hide-overlay
                                width="90%">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn  class="hidden-sm-and-down"
                                            icon
                                            dark
                                            @click="dialogGalleryPhotos = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Administrador de Imágenes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn  dark
                                                text
                                                @click="dialogGalleryPhotos = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <!--v-model="model"-->
                                                <v-slide-group
                                                        center-active
                                                        class="pa-0"
                                                        next-icon="mdi-arrow-right-drop-circle-outline"
                                                        prev-icon="mdi-arrow-left-drop-circle-outline"
                                                        show-arrows>
                                                    <v-slide-item
                                                            v-for="itemImage in editedItem.pictures"
                                                            :key="itemImage.id"
                                                            v-slot="{ active, toggle }">
                                                        <v-card
                                                                :color="active ? undefined : 'white'"
                                                                class="ma-4 rounded-lg"
                                                                height="auto"
                                                                width="250"
                                                                elevation="5"
                                                                @click="toggle">
                                                            <v-img
                                                                    :src="itemImage.url != null ? itemImage.url : require('@/assets/images/no-image.jpg')"
                                                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                                                    aspect-ratio="1"
                                                                    height="250"
                                                                    class="rounded-lg white--text align-end justify-start text-end elevation-10">
                                                                <v-btn
                                                                        dark
                                                                        icon
                                                                        elevation="0"
                                                                        class="ma-2"
                                                                        v-on:click="deletePicture(itemImage)">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                            
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                            class="fill-height ma-0"
                                                                            align="center"
                                                                            justify="center">
                                                                        <v-progress-circular
                                                                                indeterminate
                                                                                color="grey lighten-5"
                                                                        ></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-card>
                                                    </v-slide-item>
                                                </v-slide-group>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedFiles"
                                                  small-chips
                                                  accept="image/png, image/jpeg, image/bmp"
                                                  show-size
                                                  multiple
                                                  clearable
                                                  label="Agregar ficheros"
                                                  @change="inputChanged">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close @click:close="removeChip(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="imageFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in imageFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn dark color="primary" @click="uploadPictures()">Actualizar Galería</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="loadGalleryImages(item)">
                                mdi-camera
                            </v-icon>
                        </template>
                        <span>Administrar Imágenes</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado!</span>
                    <br/>
                    <v-btn color="primary" @click="getClinics">
                        Recargar
                    </v-btn>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="10">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="2">
                                            Dirección:
                                        </v-col>
                                        <v-col>
                                            {{ item.address }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="2">
                                            Email:
                                        </v-col>
                                        <v-col>
                                            {{ item.email }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="2">
                                            Sitio Web:
                                        </v-col>
                                        <v-col>
                                            {{ item.web_page }}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            max-height="150"
                                            max-width="150"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row :dense="$vuetify.breakpoint.smAndDown" class="mx-1 my-5">
                                <v-col cols="12" md="6">
                                    Teléfono 1: {{ item.phone1 | formatPhone }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Teléfono 2: {{ item.phone2 | formatPhone }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Perfil en Facebook: {{ item.facebook_profile }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Perfil en Instagram: {{ item.instagram_profile }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Canal de Youtube: {{ item.youtube_video }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Página Web: {{ item.web_page }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Licencia Sanitaria: {{ item.sanitary_license }}
                                </v-col>
                                <v-col cols="12" md="6">
                                    Horarios de Atención: {{ item.servicehours }}
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
            <v-snackbar
                    v-model="snackBar"
                    :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn  v-bind="attrs"
                            icon
                            @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import clinicsService from "@/providers/ClinicsService";
    import specialtiesService from "@/providers/SpecialtiesService";
    import certificationsService from '@/providers/CertificationsService';

    export default {
        name: "ClinicsModuleComponent",
        data: () => ({
            // MapBox Location
            placeNameLimit: 60,
            selectedPlace: {
                place_name: '',
                center: []
            },
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
            // Default data
            focus: false,
            snackBar: false,
            snackText: '',
            valid: true,
            searchClinic: "",
            loadingData: false,
            dialog: false,
            dialogDelete: false,
            dialogError: false,
            dialogDeletePicture: false,
            dialogGalleryPhotos: false,
            messageError: '',
            currentPicture: undefined,
            selectedFiles: [],
            imageFiles: [],
            specialtiesList: [],
            certificationsList: [],
            extraServicesList: [],
            doctorsList: [],
            clinics: [],
            expanded: [],
            headers: [
                {text: '', value: 'data-table-expand', width: 5 },
                {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
                {
                    text: "Nombre",
                    align: "start",
                    sortable: false,
                    value: "name"
                },
                {text: "Dirección", value: "address"},
                {text: "E-mail", value: "email"},
                {text: "Acciones", value: "actions", width: 105, sortable: false, filterable: false}
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value => !value || value.size < 2000000 || "El tamaño de la imagen no debe de sobrepasar los 2 MB!",
                // this.imagePreview == null || 'La imagen asociada es obligatoria!'
                v => {
                    if (this.imagePreview || this.imagePreview == null) {
                        return 'La imagen asociada es obligatoria!'
                    }
                    if (this.imagePreview && v) {
                        return false
                    }
                    return "Either fill out a complete image, or no image at all."
                }
            ],
            facebookRules: [
                f => !f ? true : this.regexMatchesText('facebook', f) || 'Enter a valid facebook url'
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: "",
                description: "",
                address: "",
                phone1: "",
                phone2: "",
                map_lat: 0.0,
                map_lng: 0.0,
                email: "",
                sanitary_license: "",
                web_page: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                membership: 0,
                servicehours: '',
                translation_id: 0,
                pictures: [],
                specialties: [],
                certifications: [],
                doctors: [],
                extraservices: [],
            },
            defaultItem: {
                id: 0,
                name: "",
                description: "",
                address: "",
                phone1: "",
                phone2: "",
                map_lat: 0.0,
                map_lng: 0.0,
                email: "",
                sanitary_license: "",
                web_page: "",
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                membership: 0,
                servicehours: '',
                translation_id: 0,
                pictures: [],
                specialties: [],
                certifications: [],
                doctors: [],
                extraservices: [],
            }
        }),
        filters: {
            formatPhone (value) {
                if (value === null) {
                    return '';
                }
                return "(" + value.substring(0,3) + ") " + value.substring(3,6) + "-" + value.substring(6);
            }
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "Nueva Clinica" : "Editar Clinica";
            },
            places () {
                let listPlaces = [];
                if (this.placesList) {
                    listPlaces = this.placesList.map(entry => {
                        const PlaceName = entry.place_name.length > this.placeNameLimit
                            ? entry.place_name.slice(0, this.placeNameLimit) + '...'
                            : entry.place_name;
                        console.log('PlaceName: ', PlaceName);
                        // const item = Object.assign( {}, entry);
                        // console.log('current item: ', item);
                        // return Object.assign({}, entry, { PlaceName })
                        return Object.assign({}, entry);
                    });
                }
                return listPlaces;
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            dialogDeletePicture(val) {
                val || this.closeDeletePicture();
            },
            async searchQuery (val) {
                // console.log('val:', val);
                if (val === null || val.length === 0) return;
                // Items have already been loaded
                // let url = https://api.mapbox.com/geocoding/v5/mapbox.locations/${this.heart[0]},${this.heart[1]}.json?access_token=${this.access_token}
                // let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?types=address&country=US&access_token=${this.mapBoxApiKey}`;
                let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?types=address&access_token=${this.mapBoxApiKey}`;
                this.loadingPlaces = true;
                // Lazily load input items
                fetch(url)
                    .then(res => res.json())
                    .then(res => {
                        // console.log('res: ', res);
                        const { query, features } = res;
                        this.query = query;
                        this.placesList = features;
                        // console.log('places: ', this.places);
                    })
                    .catch(err => {
                        console.log('Error ejecutando API: ', err);
                    })
                    .finally(() => (this.loadingPlaces = false));
            },
        },
        methods: {
            onClear() {
                this.$emit("input", null);
                this.$emit("clear");
            },
            getSelectedItem(item) {
                // console.log('Item selected: ', item);
                this.selectedPlace = Object.assign( {}, item);
            },
            getClinics() {
                this.loadingData = true;
                // carga el nomenclador de especialidades
                specialtiesService.getViewList().then(record => {
                    this.specialtiesList = record.value;
                    // console.log("Especialidades: ", this.specialtiesList);
                });
                certificationsService.getViewList().then(record => {
                    this.certificationsList = record.value;
                    // console.log("Certificaciones: ", this.certificationsList);
                });
                // Carga los datos de las Clinicas desde el proveedor de datos...
                clinicsService.getViewList().then(record => {
                    this.clinics = record.value;
                    // console.log("Clinicas: ", this.clinics);
                    this.loadingData = false;
                });
            },
            newItem() {
                this.valid = false;
                this.editedItem = Object.assign({}, this.defaultItem);
            },
            editItem(item) {
                this.editedIndex = this.clinics.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.clinics.indexOf(item);
                this.editedItem = Object.assign({}, item);
                /*if (this.editedItem.pictures.length > 0) {
                    this.snackText = 'No es posible eliminar este registro con imágenes asociadas.'+
                        'Por favor vaya a la galería y elimine todas las imágenes primero.';
                    this.snackBar = true;
                    messageError = 'No es posible eliminar este registro con imágenes asociadas.'+
                        'Por favor vaya a la galería y elimine todas las imágenes primero.';
                    dialogError = true;
                    this.editedItem = Object.assign({}, this.defaultItem);
                    return;
                }*/
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                clinicsService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            // console.log('Response', response);
                            this.clinics.splice(this.editedIndex, 1);
                            // console.log('editedItem:', this.editedItem.id);
                            this.snackText = 'Registro eliminado con éxito.';
                            this.getClinics();
                        } else {
                            this.snackText = 'Un error impidió eliminar el registro!';
                        }
                    }
                )
                this.closeDelete();
                this.snackBar = true;
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // console.log('selectedPlace: ', this.selectedPlace);
                    if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
                        this.editedItem.address = this.selectedPlace.place_name;
                        this.editedItem.map_lng = this.selectedPlace.center[0];
                        this.editedItem.map_lat = this.selectedPlace.center[1];
                    }
                    // console.log('editedItem: ', this.editedItem);
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.clinics[this.editedIndex], this.editedItem);
                        clinicsService.updateRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getClinics();
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.clinics.push(this.editedItem);
                        clinicsService.addRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getClinics();
                                }
                            }
                        );
                    }
                    this.close();
                    this.snackText = 'Registro guardado con éxito...';
                    this.snackBar = true;
                }
            },
            /* Para administrar las imagenes */
            loadGalleryImages(item) {
                this.selectedFiles= [];
                this.imageFiles = [];
                // Carga las imagenes...
                clinicsService.getRecord(item.id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                });
                if (!this.dialogGalleryPhotos) this.dialogGalleryPhotos = true;
            },
            removeChip (index) {
                this.imageFiles.splice(index, 1);
                this.selectedFiles = [
                    ...this.imageFiles,
                ];
            },
            inputChanged () {
                this.imageFiles = [
                    ...this.selectedFiles,
                ];
            },
            uploadPictures() {
                // console.log('id del record: ', this.editedItem.id);
                let errorCount = 0;
                for (let i = 0; i < this.imageFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                    };
                    reader.readAsDataURL(this.imageFiles[i]);
                    clinicsService.uploadImage(this.editedItem.id, -1, this.imageFiles[i]).then(result => {
                        if (result.success) {
                            this.loadGalleryImages(this.editedItem);
                        } else {
                            errorCount++;
                        }
                    });
                }
                this.snackText = (errorCount > 0) ?
                    'Un error impidió subir al menos una imágen al servidor!' :
                    'Imágen subida al servidor. Galería actualizada!';
                this.snackBar = true;
            },
            deletePicture(item) {
                // Elimina la imagen seleccionada desde la bd y del servidor...
                this.currentPicture = Object.assign({}, item);
                this.dialogDeletePicture = true;
            },
            deleteItemConfirmPicture() {
                clinicsService.deleteImage(this.currentPicture.pivot.clinic_id, this.currentPicture.id).then(result => {
                    // console.log("Resultado de la eliminacion: ", result);
                    this.closeDeletePicture();
                    if (result.success) {
                        this.snackText = 'Imagen eliminada desde la galería!';
                        this.loadGalleryImages(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar la imágen desde la galería!';
                    }
                    this.snackBar = true;
                });
            },
            closeDeletePicture() {
                this.dialogDeletePicture = false;
            },
            closeDialogError() {
                this.dialogError = false;
                this.messageError = '';
            }
        },
        mounted() {
            this.clinics = [];
            this.getClinics();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
    .row-pointer>.v-data-table__wrapper>table>tbody>tr :hover {
        cursor: pointer !important;
    }
    .table-cursor tbody tr:hover {
        cursor: pointer !important;
    }
    .text-block {
        white-space: pre;
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow-x: auto;
    }
</style>