<template>
    <clinics-module-component/>
</template>

<script>
    import ClinicsModuleComponent from "@/components/admin/modules/ClinicsModuleComponent";

    export default {
        name: "ClinicsModule",
        title: "Gestión de Clínicas | Turismo BC",
        components: {ClinicsModuleComponent}
    }
</script>

<style scoped>

</style>